import * as React from 'react';
import Pages from 'components/Pages';
import CustomContainer from 'components/CustomContainer';
import BannerImage from 'images/404.png';
import { Link } from 'gatsby';

const NotFoundPage = () => {
  return (
    <Pages>
      <div
        className="bg-black text-white h-screen flex items-center"
        style={{
          backgroundImage: `url('${BannerImage}')`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <CustomContainer className="flex flex-col items-center">
          <div className="font-extrabold text-7xl sm:text-8xl lg:text-[150px]">
            404
          </div>
          <div className="text-2xl sm:text-4xl lg:text-5xl mb-5 text-center">
            Something’s missing.
          </div>
          <div className="font-extralight text-base sm:text-xl lg:text-2xl text-center mb-10">
            This page is missing or you assembled
            <br /> the link incorrectly.
          </div>
          <Link to="/">
            <div className="font-semibold text-base sm:text-lg lg:text-xl bg-[#FF4C00] py-4 px-10 rounded-full hover:opacity-70">
              Back to home
            </div>
          </Link>
        </CustomContainer>
      </div>
    </Pages>
  );
};

export default NotFoundPage;
